// Bundle fontawesome css.
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config, library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons"
import { faBook, faStamp, faUsers } from "@fortawesome/free-solid-svg-icons"

library.add(fab, faCheckSquare, faCoffee, faBook, faStamp, faUsers)
// Prevent fontawesome from dynamically adding its css.
config.autoAddCss = false

require("typeface-quicksand")
require("typeface-source-sans-pro")
